export const EXPORT_TO_CSV = "exportToCsv";
export const ACTIVITY_NEW = "activitynew";
export const EXPORT_ALL_COLUMNS = "exportAllColumns";
export const DURATION = "Duration";
export const RECORDING_GLOCATION = "Recording.Location";
export const INITIATION_TIMESTAMP = "InitiationTimestamp";
export const DISCONNECT_REASON = "DisconnectReason";
export const QUEUEDETAILS_DURATION = "QueueDetails.Duration";
export const ATTACHMENT = "ATTACHMENT";
export const SENDING_ATTACHMENT = "SENDING_ATTACHMENT";
